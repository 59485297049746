export const abandonedHotelQuery = `
  ... on AbandonedHotel {
    isBooked
    hotelURL:url
    genericListingsUrl
    checkInDate
    checkOutDate
    numberOfAdults
    numberOfRooms
    pricePerNightAtAbandonment
    dealType
    productType
    hotelInfo {
      ... on RtlHotel {
        __typename
        thumbnail {
            source
        }
        name
        starRating
        reviewSummaryWithEntityInput {
            totalReviews
            scoreBreakdown {
                overall
            }
        }
        neighborhood {
            name
        }
      }
      ... on SopqHotel {
        __typename
        name
        starRating
        neighborhood {
          name
        }
        location {
          name
        }
      }
      ... on AbandonedPriceBreakersHotel {
          __typename
          pricebreakerDescription
          pricebreakerLocation
      }
    }
  }
`

export const abandonedRentalCarQuery = `
  ... on AbandonedRentalCar {
    isBooked
    carURL: url
    genericListingsUrl
    averageRating
    carExample
    carType
    imageURL: imageUrl
    isDropoffAtDifferentLocation
    numberOfReviews
    passengerCapacity
    pickupDateTime
    pickupLocation
    retailTypeCarCompanyDetails {
      brandImageURL: brandImageUrl
    }
    returnDateTime
    returnLocation
  }
`

export const abandonedFlightQuery = `
  ... on AbandonedFlight {
    flightURL: url
    genericListingsUrl
    tripType
    dealType
    isBooked
    numberOfAdults
    numberOfChildren
    numberOfLapInfants
    originDestinationInfo {
      travelStartDateTime
      originAirportInfo {
        city
        code
        country
        name
        state
      }
      destinationAirportInfo {
        city
        code
        country
        name
        state
      }
      flightPath {
        originAirportCode
        destAirportCode
        travelDateTime
      }
      ... on RoundTripInfo {
        travelEndDateTime
      }
      ... on MultiDestinationInfo {
        travelEndDateTime
      }
    }
  }           
`
