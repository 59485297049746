import { useQuery } from '@tanstack/react-query'
import analytics from '@/shared-utils/analytics'
import type {
  AbandonedSelection,
  SearchProductList
} from '@/components/TripActivity/types'
import fetchAbandonedSelections from '@/shared-utils/fetchAbandonedSelections'
import { getFirstValidAbandonedSelection } from '../utils'

export default function useAbandonedSelections(
  cguid: string,
  appName: string,
  appVersion: string,
  signedIn: boolean,
  productType: SearchProductList
) {
  const { data = null, error } = useQuery<AbandonedSelection | null>({
    queryKey: [cguid, appName, appVersion, productType],
    queryFn: async () => {
      const selections = await fetchAbandonedSelections(
        cguid,
        appName,
        appVersion,
        productType
      )

      return getFirstValidAbandonedSelection(selections)
    },
    staleTime: Infinity,
    enabled: !signedIn
  })

  if (error) {
    analytics.logError({
      message: 'Error thrown from useQuery',
      error: JSON.stringify(error),
      service: 'fetchAbandonedSelections'
    })
  }

  return data
}
