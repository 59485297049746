import config from 'isomorphic-config'
import { v4 as uuidv4 } from 'uuid'
import type { GraphResponseData } from '@/types'
import type { PriceWatchData } from '@/components/TripActivity/types'
import isAbortSignalTimeoutSupported from './is-abortSignal-supported'
import { jsonContentTypeHeader } from '../server/constants'
import analytics from './analytics'
import getAuthorizationToken from './get-authorization-token'

type PriceWatchesQueryResults<T> = {
  readonly airPriceWatchGetListResponse: T
}

type PriceWatchesApiResponse = {
  priceWatches: PriceWatchData[]
}

type PriceWatchesGraphResponse = GraphResponseData<
  PriceWatchesQueryResults<PriceWatchesApiResponse>
>

function isValidGraphQLResponse(
  payload: unknown
): payload is PriceWatchesGraphResponse {
  if (payload && payload instanceof Object) {
    if (
      'data' in payload &&
      payload.data &&
      typeof payload.data === 'object' &&
      'airPriceWatchGetListResponse' in payload.data
    ) {
      return true
    }
  }
  return false
}

async function fetchPriceWatches(
  email: string,
  appName: string,
  appVersion: string
) {
  const { url, timeout } = config.client['pcln-graph']
  const options = {
    method: 'POST',
    signal: isAbortSignalTimeoutSupported()
      ? AbortSignal.timeout(timeout)
      : undefined,
    headers: {
      ...jsonContentTypeHeader,
      'apollographql-client-name': appName,
      'apollographql-client-version': appVersion,
      Authorization: getAuthorizationToken()
    },
    body: JSON.stringify({
      query: `
        query AirPriceWatchGetListResponse($input: AirPriceWatchGetListRequest) {
          airPriceWatchGetListResponse(input: $input) {
            priceWatches {
              cabinClass
              dealPrice
              departDate
              destAbbreviation
              destCityId
              destCityName
              destStateName
              lastNotifiedPrice
              originAbbreviation
              originCityId
              originCityName
              originStateName
              returnDate
              savings
              savingsPercentage
              tripType
            }
          }
        } 
      `,
      variables: {
        input: {
          requestId: uuidv4(),
          showMetaInfo: true,
          email
        }
      }
    })
  }
  try {
    const response = await fetch(
      `${url}?gqlOP=priceWatchGetListRequest`,
      options
    )
    const { status, url: urlValue } = response
    if (!response.ok) {
      analytics.logError({
        message:
          'The airPriceWatchGetListResponse graph query response status is > 299',
        url: urlValue,
        status
      })
      return []
    }
    const payload = await response.json()
    if (isValidGraphQLResponse(payload)) {
      return payload.data.airPriceWatchGetListResponse.priceWatches
    }

    analytics.logError({
      message: `The airPriceWatchGetListResponse graph query response is not as per contract`,
      payload: JSON.stringify(payload)
    })
    return []
  } catch (error) {
    analytics.logError({
      message: 'Failed to call the airPriceWatchGetListResponse',
      error: JSON.stringify(error)
    })
    return []
  }
}

export default fetchPriceWatches
