import React, { useEffect } from 'react'
import { Carousel } from 'pcln-carousel'
import { ActionCard } from '@pcln/cards'
import { getRecentSearchDisplayProps, onCardClick } from './utils'
import type { RecentTripSearches } from './types'
import { fireRecentSearchLoadEvent } from './ga4'

export default function RecentSearchCarousel({
  analyticsCategory,
  items
}: {
  analyticsCategory: 'PCLN_HOME' | 'HOTEL_HOME'
  items: ReadonlyArray<RecentTripSearches>
}) {
  useEffect(() => {
    if (items.length > 0) {
      fireRecentSearchLoadEvent(items, 'PCLN_HOME')
    }
  }, [items])
  return (
    <Carousel
      arrowsPositions="top"
      arrowsPosition="side"
      currentSlide={0}
      displayArrowsMobile={false}
      slideSpacing={1}
      onSlideChange={() => {
        // do  nothing
      }}
      visibleSlides={[2.5, 2.5, 3.5]}
      mobileVisibleSlides={[1.2, 2.5]}
    >
      {items.map((item, index) => {
        const { overline, heading, icon, actionText, relativeUrl } =
          getRecentSearchDisplayProps(item)
        return (
          <ActionCard
            key={overline || index}
            headingWidth={['224px', null, null, '250px']}
            icon={icon}
            heading={heading}
            actionText={actionText}
            overline={overline}
            onClick={() => {
              onCardClick(
                item,
                relativeUrl,
                analyticsCategory,
                item.productType
              )
            }}
            handleSubmitCTA={() => {
              onCardClick(
                item,
                relativeUrl,
                analyticsCategory,
                item.productType
              )
            }}
            className="full-width"
          />
        )
      })}
    </Carousel>
  )
}
