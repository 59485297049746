import type { LOCATION_SEARCH_TYPE } from '@/types'
import useBootstrapData from './useBootstrapData'

export default function useCityNameInFormHeadlineForSEM(
  location?: LOCATION_SEARCH_TYPE
) {
  const { prePopData } = useBootstrapData()
  const endLocation = prePopData?.hotels?.endLocation
  const hasCity = !!endLocation?.cityName && endLocation?.type === 'CITY'
  return hasCity
    ? `Search ${location?.cityName || endLocation?.cityName || ''} Hotels`
    : null
}
