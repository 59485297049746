import React, { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Box, Text, Button, Flex, Heading, theme } from 'pcln-design-system'
import AbandonedSelection from '@/components/TripActivity/AbandonedSelection'
import useSeti from '@/hooks/useSeti'
import useBootstrapData from '@/hooks/useBootstrapData'
import { onViewAllButtonClick } from './utils'
import useTripActivityData from './hooks/useTripActivityData'
import StyledWrapper from './TripActivity.styled'
import type {
  AbandonedSelection as AbandonedSelectionType,
  RecentSearchAndPriceWatchList,
  RecentTripSearches,
  SearchProductList
} from './types'
import ConditionalRender, { Original, Experiment } from '../ConditionalRender'
import RecentSearchCarousel from './RecentSearchCarousel'
import RecentSearchAndPriceWatchCarousel from './RecentSearchAndPriceWatchCarousel'

function isRecentTripSearches(
  _item: ReadonlyArray<RecentTripSearches> | RecentSearchAndPriceWatchList,
  isPriceWatchVariant: boolean
): _item is RecentTripSearches[] {
  return isPriceWatchVariant === false
}
export default function TripActivity({
  productType,
  analyticsCategory,
  setIsVisible,
  shouldUseSmallerHeading,
  abandonedSelectionData,
  hideAbandonedSelection
}: {
  setIsVisible?: React.Dispatch<React.SetStateAction<boolean>>
  productType: SearchProductList
  analyticsCategory: 'PCLN_HOME' | 'HOTEL_HOME'
  shouldUseSmallerHeading?: boolean
  abandonedSelectionData?: AbandonedSelectionType | null
  hideAbandonedSelection?: boolean
}) {
  const {
    signInStatus: { signedIn }
  } = useBootstrapData()
  const isDesktop = useMediaQuery({
    query: `(min-width: ${theme.breakpoints.lg as string})`
  })
  const isShowPriceWatch = useSeti('HP_PUWYLO_PRICEWATCH', false) === 'VARIANT'
  const { atMostFiveRecentSearchCards, abandonedSelection } =
    useTripActivityData(productType, abandonedSelectionData)

  const hasRecentSearchCards = atMostFiveRecentSearchCards.length > 0
  useEffect(() => {
    if (setIsVisible) {
      setIsVisible(hasRecentSearchCards || abandonedSelection !== null)
    }
  }, [hasRecentSearchCards, abandonedSelection, setIsVisible])

  return hasRecentSearchCards ||
    (abandonedSelection && !hideAbandonedSelection) ? (
    <StyledWrapper borderRadius="2xl" bg="priceSecondary.light">
      <Box>
        <Flex justifyContent="space-between" alignItems="flex-start">
          <Heading
            mt={0}
            as="h2"
            mb={3}
            ml={[1, null, null, 0]}
            textStyle={
              shouldUseSmallerHeading
                ? ['heading4', 'heading3']
                : ['heading3', 'heading2']
            }
            color="text.heading"
          >
            Pick up where you left off
          </Heading>
          {isDesktop && (
            <Button
              variation="white"
              data-testid="view-all-button-top-right"
              onClick={() => onViewAllButtonClick(analyticsCategory)}
              {...(shouldUseSmallerHeading && { size: 'small' })}
            >
              View All Recent Activity
            </Button>
          )}
        </Flex>
        {abandonedSelection && !hideAbandonedSelection && (
          <>
            <Box mb={2}>
              <AbandonedSelection
                abandonedSelection={abandonedSelection}
                analyticsCategory={analyticsCategory}
              />
            </Box>
            {abandonedSelection.productType === 'hotels' && (
              <Text textStyle="caption" pt={2} pl={[0, null, 2]}>
                *Availability and pricing are subject to change
              </Text>
            )}
            {hasRecentSearchCards && (
              <Text
                textStyle="heading4"
                color="text.heading"
                pt={3}
                pb={[2, null, 3]}
              >
                Or continue browsing
              </Text>
            )}
          </>
        )}
        {hasRecentSearchCards && (
          <ConditionalRender
            experimentCode="HP_PUWYLO_PRICEWATCH"
            triggerExperiment={() => {
              return signedIn
            }}
          >
            <Original>
              <RecentSearchCarousel
                items={
                  isRecentTripSearches(
                    atMostFiveRecentSearchCards,
                    isShowPriceWatch
                  )
                    ? atMostFiveRecentSearchCards
                    : []
                }
                analyticsCategory={analyticsCategory}
              />
            </Original>
            <Experiment>
              <RecentSearchAndPriceWatchCarousel
                items={
                  !isRecentTripSearches(
                    atMostFiveRecentSearchCards,
                    isShowPriceWatch
                  )
                    ? atMostFiveRecentSearchCards
                    : []
                }
                analyticsCategory={analyticsCategory}
              />
            </Experiment>
          </ConditionalRender>
        )}

        {!isDesktop && (
          <Box pt={24}>
            <Button
              variation="white"
              width={1}
              data-testid="view-all-button-bottom"
              onClick={() => onViewAllButtonClick(analyticsCategory)}
              {...(shouldUseSmallerHeading && { size: 'small' })}
            >
              View All Recent Activity
            </Button>
          </Box>
        )}
      </Box>
    </StyledWrapper>
  ) : null
}
